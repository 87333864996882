import { FormEvent } from "primereact/ts-helpers";
import { ChangeEvent, SyntheticEvent, useId, useState } from "react";
import DatePickerWrapper from "../../date-picker-wrapper/DatePickerWrapper";
import { RequestedTimeOff } from "../../tp-core-types/RequestedTimeOff";
import "./TimeOffRow.scss"

interface Props {
    onChange: (timeoff: RequestedTimeOff) => void;
    timeOff: RequestedTimeOff;
}

export default function TimeOffRow(props: Props) {
    const [isSingleDay, setIsSingleDay] = useState(false);

    const handleOnFromDateChange = (e: FormEvent<Date, SyntheticEvent<Element, Event>>): void => { 
        const newTimeOff = { ...props.timeOff, fromDate: e.value };
        props.onChange(newTimeOff); 
    };

    const handleOnToDateChange = (e: FormEvent<Date, SyntheticEvent<Element, Event>>): void => { 
        const newTimeOff = { ...props.timeOff, toDate: e.value };
        props.onChange(newTimeOff);     
    };
    
    function handleSingledayCheckbox(event: ChangeEvent<HTMLInputElement>): void {
        setIsSingleDay(event.target.checked)
        const newTimeOff = {...props.timeOff, toDate: null}
        props.onChange(newTimeOff);
    }

    const uniqFromLabel = useId();
    const uniqToLabel = useId();
    const uniqCheckBoxLabel = useId();


    return(
        <div id="TimeOffRow">
            <div className="dateRow">
                <div className="dateRowItem">
                    <label htmlFor={uniqFromLabel}>{isSingleDay? "Day" : "From" }</label>
                    <DatePickerWrapper inputId={uniqFromLabel} value={props.timeOff.fromDate} onChange={handleOnFromDateChange} />
                </div>
                <div className="dateRowItem">
                    <div className="toLabelRow">
                        <label htmlFor={uniqToLabel} className={isSingleDay? "hidden": ""}>To</label>
                        <div>
                            <label htmlFor={uniqCheckBoxLabel}>Single day</label>
                            <input id={uniqCheckBoxLabel} type="checkbox" onChange={handleSingledayCheckbox}/>
                        </div>
                    </div>
                    <div  className={isSingleDay? "hidden": ""}>
                        <DatePickerWrapper inputId={uniqToLabel} value={props.timeOff.toDate} onChange={handleOnToDateChange} />
                    </div>
                </div>
            </div>
        </div>
        

    )

}