import { createContext } from "react";

export type FeatureFlagsModel = {
    featureFlag : {
        featureFlag_HcpApplication_IsEnabled: boolean,
        featureFlag_HcpApplication_SkillsChecklist_IsEnabled: boolean,
    }
}

const defaultValue: FeatureFlagsModel = {
    featureFlag: {
        featureFlag_HcpApplication_IsEnabled: false,
        featureFlag_HcpApplication_SkillsChecklist_IsEnabled: false,
    }
}

const FeatureFlagContext = createContext(defaultValue);

export default FeatureFlagContext;