import { useContext, useEffect, useState } from "react"
import "./SubmittedJobResults.scss"
import { JobModel } from "../tp-core-types/JobModel"
import { JobSubmissionResult } from "../tp-core-types/JobSubmissionResult";
import JobSearchCard from "../job-search-card/JobSearchCard";
import JobSearchDetails from "../job-search-details/JobSearchDetails";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { SubmittedJobDetail } from "../tp-core-types/SubmittedJobDetail";
import LoginModal from "../login-modal/LoginModal";
import { Session } from "../utilities/Session";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";

export default function SubmittedJobResults() {
    const userInfoModel = useContext(UserInfoModel)
    const [jobs, setJobs] = useState<JobSubmissionResult>();
    const [selectedJob, setSelectedJob] = useState<JobModel | null>(null)
    const [submittedJobDetails, setSubmittedJobDetails] =  useState<SubmittedJobDetail | null>(null);
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);

    const isMobile = window.matchMedia("(max-width: 1250px)").matches;

    useEffect(() => {
        userInfoModel.subscribeForLogout(handleLogout)
        if (Session.getHcpLoginToken() !== ""){
            getSubmittedJobResults();
            setShowLoginModalVisibility(false);
        } else {
            setShowLoginModalVisibility(true);
        }
        return function cleanup() {
            userInfoModel.unsubscribeForLogout(handleLogout)
        }
    }, []);

    useEffect(() => {
        if (selectedJob != null) {
            getSubmittedJobDetails(selectedJob)
        }
    }, [selectedJob])

    const getSubmittedJobResults = async () => {
        const results = await TPCoreAPI.getSubmittedJobs();
        setJobs(results);
        if (!isMobile && results.submittedJobs.length > 0 ) {
            setSelectedJob(results.submittedJobs[0]);
        }
    }

    const getSubmittedJobDetails = async (job: JobModel) => {
        const results = await TPCoreAPI.getSubmittedJobDetails(job.jobId);
        setSubmittedJobDetails(results);
    }

    const handleJobClick = (job: JobModel) => {
        setSelectedJob(job);
    };

    const handleLogout = () => {
        setShowLoginModalVisibility(true);
    }

    const handleLogin = async() => {
        setShowLoginModalVisibility(false);
        getSubmittedJobResults();
    }

    const clearJobSelection = () => {
        setSelectedJob(null);
    }

    return (
        <div id="SubmittedJobResults">
            <LoginModal isLoginForm={true} isVisible={showLoginModalVisibility} setVisibility={(value: boolean) => setShowLoginModalVisibility(value)} successfulLogin={handleLogin} headerText="Your session has expired, please log in again." canBeDismissed={false}/>
            {isMobile && 
                <GenericPageHeader title={jobs?.submittedJobs.length ? `Current Submissions (${jobs?.submittedJobs.length})` : `Loading...`}></GenericPageHeader>
            }
            { !isMobile && 
                <GenericPageHeader title="Current Submissions"></GenericPageHeader>
            }
            <div className="container-style" data-testid="container-style">
                <div className="submissions-jobs-container">
                    <div className="submission-list-title">
                        {jobs ?
                        `${jobs?.submittedJobs.length} Active Submissions`
                        : "Loading..."                     
                        }
                    </div>
                    <div data-testid="submitted-job-list" className="submission-left-pane"> 
                    {jobs?.submittedJobs && jobs.submittedJobs.map((job) => {
                        return(
                            <div>
                                <div data-testid={`job${job.jobId}`} key={`job${job.jobId}`}>
                                <JobSearchCard 
                                job={job} 
                                handleJobClick={handleJobClick} 
                                selected={job.jobId === selectedJob?.jobId}
                                isArchived={false}/>
                                </div>
                            </div>
                        )})
                    }                    
                    </div>
                </div>
                {selectedJob && (
                    <JobSearchDetails neverShowActionButtons={true}  authToken="" 
                    jobId={selectedJob.jobId}
                    returnToResultsCallback={clearJobSelection}
                    submittedJobDetails={submittedJobDetails} />
                )
                }
            </div>
        </div>
    )
}