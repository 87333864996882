import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import application_icon from "../assets/application_icon.svg"
import "./GenericPageHeader.scss"

type Props = {
    title: string,
    showStartApplicationLink?: boolean,
}

export default function GenericPageHeader(props: Props) {
    const [showStartApplicationLink, setShowStartApplicationLink] = useState<boolean>(props.showStartApplicationLink ?? false);
    const userInfoModel = useContext(UserInfoModel);
    const navigate = useNavigate();
    const isMobile = window.matchMedia("(max-width: 1250px)").matches;

    useEffect(() => {
        if (userInfoModel.token !== "" && userInfoModel.userInfo.firstName !== "") { //checking first name so the start application link is hidden until the userInfo call comes back
            getCompletedSteps()
        }
    }, [navigate, userInfoModel.token, userInfoModel.userInfo]);

    const getCompletedSteps = async () => {
        const steps = await TPCoreAPI.getCompletedHCPApplicationSteps();
        setShowStartApplicationLink(!userInfoModel.userInfo.applicationComplete && steps.length === 0)
    }

    return (
        <div id ="GenericPageHeader" className="header">
            <div className="title-text">{props.title}</div>
            <div className="header-spacer"></div>
            { !isMobile && showStartApplicationLink &&
                <div className="link-container" data-testid="link-container" onClick={(e)=>{e.stopPropagation(); navigate('/apply-now')}}>
                    <img className="app-icon" src={application_icon} alt="app"></img>
                    <div className="start-link">Start TRS Application</div>
                </div>
            }
        </div>
    )
}