import { createContext } from "react";

export type UserInfo = {
    firstName: string,
    lastName: string,
    applicationComplete: boolean,
    eligibleForSubmission: boolean,
}

export type UserInfoModel = {
    userInfo: UserInfo,
    token: string,
    getAndSetToken: ()=>void,
    subscribeForLogout: (callback: () => void) => void,
    unsubscribeForLogout: (callback: () => void) => void,
}

const defaultValue: UserInfoModel = {
    userInfo: { firstName: "", lastName: "", applicationComplete: false, eligibleForSubmission: false },
    token: '',
    getAndSetToken: function (): void {return},
    subscribeForLogout: function (callback): void {return},
    unsubscribeForLogout: function (callback): void {return}
}

const UserInfoContext = createContext(defaultValue);

export default UserInfoContext;


