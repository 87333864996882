import { SubmittedJobDetail } from "../tp-core-types/SubmittedJobDetail";
import "./SubmittedJobDetails.scss"

type Props = {
     submittedJobDetail: SubmittedJobDetail
    }

export default function SubmittedJobDetails(props: Props) {

    const formatRequestedTimeOff = (fromDate: Date | undefined | null, toDate: Date | undefined | null) => {
        if (fromDate && toDate) {
            return `${fromDate.toLocaleDateString()} - ${toDate.toLocaleDateString()}`
        } else if (fromDate && !toDate) {
            return fromDate.toLocaleDateString()
        } else {
            return "-"
        }
    }

    return (
    <div id="SubmittedJobDetails">
        <div className="requests-header">
        Your Requests
        </div>
        <div className="values">
            <div id="startDate" data-testid="startDate">
                <div className="value-header">
                    Desired Start Date:
                </div>
                <div className="value">
                    {props.submittedJobDetail.desiredStartDateText !== "" ? props.submittedJobDetail.desiredStartDateText: "-"}
                </div>
            </div>
            <div id="timeOff" data-testid="timeOff">
                <div className="value-header">
                    Requested Time Off:
                </div>
                <div className="value">
                    {props.submittedJobDetail.requestedTimeOff.map((timeOff, index) => {
                        return(
                            <div key={index}>
                                {formatRequestedTimeOff(timeOff.fromDate, timeOff.toDate)}
                            </div>
                            )
                        })
                    }
                    {props.submittedJobDetail.requestedTimeOff.length === 0 && props.submittedJobDetail.timeoff !== "" && props.submittedJobDetail.timeoff}
                    {props.submittedJobDetail.requestedTimeOff.length === 0 && props.submittedJobDetail.timeoff === "" && "-"}
                </div>
            </div>
            <div id="bestTime" data-testid="bestTime">  
                <div className="value-header">
                    Best Time to Contact You:
                </div>
                <div className="value">
                    {props.submittedJobDetail.bestContactTime === "" ? "-" : props.submittedJobDetail.bestContactTime}
                </div>
            </div>
            <div id = "notes" data-testid="notes">
                <div className="value-header">
                    Other Notes:
                </div>
                <div className="value">
                    {props.submittedJobDetail.otherDetails === "" ? "-": props.submittedJobDetail.otherDetails}
                </div>
            </div>
        </div>
            
    </div>
    );
}