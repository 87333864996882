import { useContext } from "react";
import "./ApplyNow.scss";
import UserInfoContext from "../../tp-core-types/UserInfoModel";
import { useNavigate } from "react-router-dom";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import FeatureFlagContext from "../../tp-core-types/FeatureFlagsModel";

export default function ApplyNow() {
    const userInfo = useContext(UserInfoContext);
    const navigate = useNavigate();
    const ff = useContext(FeatureFlagContext);

    const handleClick = async () => {
        navigate("/background-questions");
        TPCoreAPI.postWebApplicationStep({StepComplete: true, StepName: "Started"});
    };

    return (
        <div id="ApplyNow">
            <div className="application-header">Application</div>
            {userInfo.userInfo.firstName !== "" ? (
                <div className="greeting"><p>{userInfo.userInfo.firstName}, your application will include the following sections</p></div>
                ) : (
                <p></p>
           )}

            <div className="body-container">
                
                <div className="round-corners">
                    <div className="inline-container">
                        <div className="left-container">
                            <div className="large-number">1</div>
                            <div className="step-name">Create Your Profile</div>
                        </div>
                        
                        <div className="right-container">
                            <div className="step-description">
                                <p>Employment history, education, license, etc.</p>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    {ff.featureFlag.featureFlag_HcpApplication_IsEnabled && ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled &&
                    <>
                        <div className="inline-container">
                            <div className="left-container">
                                <div className="large-number">2</div>
                                <div className="step-name">Complete Skills Checklist</div>
                            </div>                    
                            <div className="right-container">
                                <div className="step-description">
                                    <p>Skill proficiency and frequency for your specialty</p>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                    </>
                    }
                    <div className="inline-container">
                        <div className="left-container">
                            <div className="large-number">{ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled ? "3" : "2"}</div>
                            <div className="step-name">Submit Profile to TRS</div>
                        </div>
                        <div className="right-container">
                            <div className="step-description">
                                <p>Submit completed profile to join TRS</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apply-now-button-container">
                    <button className="get-started-button" onClick={handleClick}>
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    );
}
