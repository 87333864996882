import { EmploymentHistoryOptionsResult, EmploymentHistory, EmploymentHistoryFormData} from "../../tp-core-types/EmploymentHistory";
import { useEffect, useState } from "react";
import { MonthOption } from "../../utilities/monthOption";
import DateService from "../../utilities/dateService";
import "./EmploymentHistoryRecord.scss";
import TrashCan from "../../assets/trash-can.svg";
import DisabledTrashCan from "../../assets/disabled-trash-can.svg";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import Verified from "../../assets/verified.png"
import EmploymentHistoryForm from "../../shared-forms/EmploymentHistoryForm";
import { EmploymentHistorySaveResult } from "../../tp-core-types/EmploymentHistorySaveResult";

type Props = {
    options: EmploymentHistoryOptionsResult | undefined
    recordTitle: string
    data: EmploymentHistory
    updateEmploymentHistoryRecords: (record: EmploymentHistory) => void;
    isDeleteButtonEnabled: boolean
    isEditButtonEnabled: boolean
    updateEditButton: (updateState: boolean) => void;
}

export default function EmploymentHistoryRecord(props: Props) {   
    const defaultSelectedProfessionKey = 13;
        
    const [formData, setFormData] = useState<EmploymentHistoryFormData>({
        employmentHistoryId: props.data.employmentHistoryId,
        employmentTypeId: props.data.employmentTypeId,
        facilityName: props.data.facilityName,
        city: props.data.city,
        stateId: props.data.stateId,
        startMonth: props.data.startMonth,
        startYear: props.data.startYear,
        endMonth: props.data.endMonth,
        endYear: props.data.endYear,
        iStillWorkHere: !props.data.endMonth || !props.data.endYear,
        professionId: props.data.professionId ?? defaultSelectedProfessionKey,
        agencyName: props.data.agencyName,
        isCharge: props.data.isCharge,
        answerIsFloat: props.data.unitsFloated != null,
        unitsFloated: props.data.unitsFloated,
        shiftPerMonth: props.data.shiftPerMonth,
        specialties: props.data.specialties,
        emrs: props.data.emrs
    });

    const [monthOptions, setMonthOptions] = useState<MonthOption[]>();
    const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
    const [showSummary, setShowSummary] = useState<boolean>(props.data.employmentHistoryId !== null);
    const [showEditButton, setShowEditButton] = useState<boolean>(false);   

    useEffect(() => {        
        setMonthOptions(DateService.getMonthList())
    }, []);

    function saveRecord() {
        setShowSummary(true);
        saveRecordtoDB();
        setShowEditButton(false);
        props.updateEditButton(true);
    }

    function editRecord() {
        setShowSummary(false);
        setShowEditButton(true);
        props.updateEditButton(false);
    }

    function handleFormDataChange(form: EmploymentHistoryFormData) {
        setFormData(form);
        setIsFormComplete(false);
        const isAgencyRequired = props.options?.employmentTypes.find(t => t.employmentHistoryTypeId === form.employmentTypeId)?.showAgency;
        const professionHasNoAssociatedSpecialties = props.options?.professions.find(p => p.professionId === form.professionId)?.specialties.length === 0
        const hasValidFloatSelection = (form.answerIsFloat && form.unitsFloated) || (form.answerIsFloat !== null && !form.answerIsFloat)
        if(
            form.employmentTypeId &&
            form.facilityName &&
            (form.agencyName || !isAgencyRequired) &&
            form.city &&
            form.stateId &&
            form.startMonth &&
            form.startYear &&
            ((form.endMonth && form.endYear) || form.iStillWorkHere) &&
            form.professionId &&
            form.emrs[0] &&
            (professionHasNoAssociatedSpecialties || form.specialties.length > 0) &&
            (professionHasNoAssociatedSpecialties || hasValidFloatSelection)           

        ) {
            setIsFormComplete(true)
        }
    }
 
    async function saveRecordtoDB( ){
        const shouldSendStaffingAgency = props.options?.employmentTypes.find(t => t.employmentHistoryTypeId === formData.employmentTypeId)?.showAgency;
        const professionHasAssociatedSpecialties: boolean = props.options?.professions.find(p => p.professionId === formData.professionId)?.specialties.length !== 0
        const data: EmploymentHistory = {
            employmentHistoryId: props.data.employmentHistoryId,
            employmentTypeId: formData.employmentTypeId as number,
            facilityName: formData.facilityName as string,
            city: formData.city as string,
            stateId: formData.stateId as number,
            startMonth: formData.startMonth as number,
            startYear: formData.startYear as number,
            endMonth: formData.endMonth,
            endYear: formData.endYear,
            professionId: formData.professionId,
            agencyName: shouldSendStaffingAgency ? formData.agencyName : null,
            isCharge: professionHasAssociatedSpecialties && formData.isCharge,
            unitsFloated: ( professionHasAssociatedSpecialties && formData.answerIsFloat ) ? formData.unitsFloated : null,
            shiftPerMonth: formData.shiftPerMonth,
            specialties: formData.specialties,
            emrs: formData.emrs,
            deleted: false,
            isVerified: false      
        }
        let response: EmploymentHistorySaveResult = {employmentHistoryId: -1};
        if(data.employmentHistoryId === null) {
            response = await TPCoreAPI.postEmploymentHistory(data);
        } else {
            await TPCoreAPI.updateEmploymentHistory(data);
        }

        const newObject = {...data, employmentHistoryId: response.employmentHistoryId !== -1 ?  response.employmentHistoryId : props.data.employmentHistoryId};
        props.updateEmploymentHistoryRecords(newObject);
    }

    const deleteEmploymentHistory = async () => {
        if (formData.employmentHistoryId) {
            await TPCoreAPI.deleteEmploymentHistory(formData.employmentHistoryId);
        }

        props.updateEmploymentHistoryRecords({
            employmentHistoryId: formData.employmentHistoryId,
            employmentTypeId: 0,
            facilityName: "",
            city: "",
            stateId: 0,
            startMonth: 0,
            startYear: 0,
            endMonth: null,
            endYear: null,
            professionId: 0,
            agencyName: null,
            isCharge: false,
            unitsFloated: null,
            shiftPerMonth: null,
            specialties: [],
            emrs: [],
            deleted: true,
            isVerified: false
        });
    }    

    function displayEndDate()
    {
        let displayValue = "";
        if (!formData.endMonth || formData.endMonth === 0)
            {
                displayValue = "Present"
            }
        else
        {
            displayValue = monthOptions?.find(m => m.number === formData.endMonth)?.name + " " + formData.endYear;
        }
        return displayValue;
    }

    return(
        <div className="EmploymentHistoryRecord" data-testid="employment-history-record">

            {showSummary &&
            <div className="record-summary" data-testid="record-summary"> 
                <div className="record-summary-facility">{formData.facilityName} - {formData.city}, {props.options?.states.find(s => s.stateId === formData.stateId)?.name}</div>
                <div className="record-summary-duration">{monthOptions?.find(m=> m.number === formData.startMonth)?.name} {formData.startYear} - {displayEndDate()}</div>
                {props.data.isVerified && <div className="verified-text">
                    <img className="verified-text-checkmark" src={Verified} alt="Verified Record Icon"/>
                    <div>TRS has verified this record. It cannot be edited or deleted.</div> 
                </div>
                }
            </div>

            }
            {props.options && !showSummary && <EmploymentHistoryForm historyFormData={formData} options={props.options} recordTitle={props.recordTitle} onChange={handleFormDataChange} />}
            <div className="save-delete-button-container">
                <div className="delete-record-button-container">
                    <button data-testid="delete-button" className="delete-record-button" onClick={() => deleteEmploymentHistory()} disabled={!props.isDeleteButtonEnabled}>
                        { !props.isDeleteButtonEnabled  && <img src={DisabledTrashCan} alt="trash can"/> }
                        { props.isDeleteButtonEnabled && <img src={TrashCan} alt="trash can"/> }
                        <div className="delete-record-text">Delete Record</div>
                    </button>
                </div>
                <div className="save-record-button-container">
                    <button id={"saveRecordButton"} className="save-record-button" disabled={!isFormComplete} onClick={saveRecord} hidden={props.data.employmentHistoryId !== null && !showEditButton}>Save Record</button>
                    <button id="editRecordButton" data-testid="edit-button" className={`edit-record-button ${!props.isEditButtonEnabled  || props.data.isVerified ? "edit-record-button-disabled" : ""}`} disabled={!props.isEditButtonEnabled || props.data.isVerified} onClick={editRecord} hidden={props.data.employmentHistoryId === null || showEditButton}>Edit Record</button>
                </div>
            </div>
        </div>
    );
}