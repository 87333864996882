import { useContext, useEffect, useState } from "react";
import ExternalJobSearchForm from "../external-job-search-form/ExternalJobSearchForm";
import "./Dashboard.scss";
import UserInfoModel, { UserInfo } from "../tp-core-types/UserInfoModel";
import AppStatusTracker from "../HCPApplication/navigation-header/AppStatusTracker";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { NavigationSteps } from "../tp-core-types/NavigationSteps";
import { AppStatusTrackerSize } from "../HCPApplication/navigation-header/AppStatusTrackerSizes";
import { useNavigate } from "react-router-dom";
import FeatureFlagContext from "../tp-core-types/FeatureFlagsModel";
import clipboard from '../assets/clipboard.png'
import bigGreenCheck from '../assets/eligibleGreenCheck.svg'
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import { BuildNavigationStepDictionary } from "../HCPApplication/navigation-header/NavigationStepDictionary";


export default function Dashboard() {
    const isMobile = window.matchMedia("(max-width: 800px)").matches;
    const navigate = useNavigate();
    const userInfoModel = useContext(UserInfoModel);
    const ff = useContext(FeatureFlagContext);
    const navigationStepDictionary = BuildNavigationStepDictionary(ff?.featureFlag?.featureFlag_HcpApplication_SkillsChecklist_IsEnabled);
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const [stepsComplete, setStepsComplete] = useState<NavigationSteps[]>([])

    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

    
    useEffect(() => {
        userInfoModel.subscribeForLogout(handleLogout)
        getCompletedSteps();
        getUserInfo();
        return function cleanup() {
            userInfoModel.unsubscribeForLogout(handleLogout)
        }
    }, []);

    const getCompletedSteps = async () => {
        const steps = await TPCoreAPI.getCompletedHCPApplicationSteps();
        setStepsComplete(steps);
    }

    const getUserInfo = async () => {
        const user = await TPCoreAPI.getUserInfo();
        setUserInfo(user);
    }

    const navigateToCurrentStep = async() => {
        const stepArray: NavigationSteps[] = [];

        let savedStep: NavigationSteps|null = NavigationSteps.Started;
        while(savedStep != null) {
            stepArray.push(savedStep);
            savedStep = navigationStepDictionary[savedStep].next;
        }

        if(stepsComplete !== undefined && stepsComplete !== null && stepsComplete.length > 0) {
            const currentStep = stepArray[stepsComplete.length - 1];
            const nextStep = navigationStepDictionary[currentStep].next;
            if (nextStep !== null) {
                navigate(navigationStepDictionary[nextStep].path)
            }
        }
       
    }

    const handleLogout = async() => {
        setShowLoginModalVisibility(true);
    }

    const handleLogin = async() => {
        setShowLoginModalVisibility(false);
    }

    const applicationStatus = () => {
        if (userInfo === undefined) {
            return (
                <div className="application-status">
                    <div className="application-status-header">
                            <div className="steps-complete-text"></div>
                    </div>
                    <div className="dashboard-application-status-container empty-container">
                        
             
                    </div>
                        
                </div>
            )
        } else if (stepsComplete.length > 0 && !userInfo?.applicationComplete && !userInfo?.eligibleForSubmission) {
            return (
                <div className="application-status">
                    <div className="application-status-header">
                        <div>Status of Your Application to TRS</div>
                            <div className="steps-complete-text">{stepsComplete.length >= 1 ? stepsComplete.length - 1 : 0} of { ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled ? "10" : "9" } Sections Completed</div>
                    </div>
                    <div className="dashboard-application-status-container">
                        {
                            !isMobile &&
                            <>
                                <div className="application-status-content">
                                    <AppStatusTracker stepsComplete={stepsComplete} size={AppStatusTrackerSize.Large} verbose={true}/>
                                </div>
                                <div className="resume-button-container">
                                    <button className="resume-button" onClick={navigateToCurrentStep}>Continue TRS Application</button>
                                </div>
                            </>
                        }
                        {
                            isMobile && 
                            <div className="application-status-content">
                                <AppStatusTracker stepsComplete={stepsComplete} size={AppStatusTrackerSize.Large} verbose={false}/>
                                <div className="resume-button-container">
                                    <button className="resume-button" onClick={navigateToCurrentStep}>Continue TRS Application</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        } 
        else if (userInfo?.applicationComplete && !userInfo.eligibleForSubmission) {
            return (
                <div className="application-status">
                    <div className="application-status-header">
                        <div>Status of Your Application to TRS</div>
                        <div className="steps-complete-text">Received</div>
                    </div>
                    <div className="dashboard-application-status-container received-container">
                        <div>
                            <img src={clipboard} data-testid="clipboard"></img>
                        </div>
                        <div className="status-container-textbox">
                            <div className="header-1">TRS Application Received!</div>
                            <div>Your application to TRS has been received! We will let you know once we complete the verification process.</div>
                        </div>
                        
                    </div>
                </div>
            )
        }
        else if (userInfo?.eligibleForSubmission) {
            return (
                <div className="application-status">
                    <div className="application-status-header">
                        <div>Status of Your Application to TRS</div>
                        <div className="steps-complete-text">Approved</div>
                    </div>
                    <div className="dashboard-application-status-container received-container">
                        <div>
                            <img src={bigGreenCheck} width={isMobile ? '60px' : '80px'} className="green-check-img" data-testid="green-check"></img>
                        </div>
                        <div className="status-container-textbox">
                            <div>Your application to TRS has been approved! You are now eligible for submission.</div>
                        </div>
                        
                    </div>
                </div>
            )
        }
        else {
            return <></>
        }
    }

    return (
        <div id="Dashboard">
            <GenericPageHeader title="Dashboard"></GenericPageHeader>
            <div className="page-container">
                {applicationStatus()}

                <div className="search-for-jobs-header" data-testid="search-for-jobs">
                    Search For Jobs
                </div>
                <div className="external-job-searchform-container">
                    <ExternalJobSearchForm />
                </div>
            </div>
        </div>
    );
}
